<template>
  <nav class="navbar navbar-expand-md navbar-dark bg-tertiary">
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbar">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="componentDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Components </a>
          <div class="dropdown-menu" aria-labelledby="componentDropdown">
            <router-link class="dropdown-item" to="/" exact>Home</router-link>
            <router-link class="dropdown-item" to="/components/buttons">Buttons</router-link>
            <router-link class="dropdown-item" to="/components/cards">Cards</router-link>
            <router-link class="dropdown-item" to="/components/collapse">Collapse</router-link>
            <router-link class="dropdown-item" to="/components/colours">Colours</router-link>
            <router-link class="dropdown-item" to="/components/dialogs">Dialogs</router-link>
            <router-link class="dropdown-item" to="/components/feedback">Feedback</router-link>
            <router-link class="dropdown-item" to="/components/forms">Forms</router-link>
            <router-link class="dropdown-item" to="/components/help">Help</router-link>
            <router-link class="dropdown-item" to="/components/icons">Icons</router-link>
            <router-link class="dropdown-item" to="/components/layout">Layout</router-link>
            <router-link class="dropdown-item" to="/components/navigation">Navigation</router-link>
            <router-link class="dropdown-item" to="/components/page-headers">Page Headers</router-link>
            <router-link class="dropdown-item" to="/components/step-wizard">Step Wizard</router-link>
            <router-link class="dropdown-item" to="/components/tables">Tables</router-link>
            <router-link class="dropdown-item" to="/components/tabs">Tabs</router-link>
            <router-link class="dropdown-item" to="/components/typography">Typography</router-link>
            <router-link class="dropdown-item" to="/components/utilities">Utilities</router-link>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="wfCommon" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> WF Common </a>
          <div class="dropdown-menu" aria-labelledby="uiShellDropdown">
            <router-link class="dropdown-item" to="/pages/dashboard">Dashboard</router-link>
            <router-link class="dropdown-item" to="/pages/manage-certificates">Manage Certificates</router-link>
            <router-link class="dropdown-item" to="/pages/land-title-correction">Land Title Correction</router-link>
          </div>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="wfPackage" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> WF Package </a>
          <div class="dropdown-menu" aria-labelledby="uiShellDropdown">
            <router-link class="dropdown-item" to="/pages/create-package">Create Package</router-link>
            <router-link class="dropdown-item" to="/pages/view-all-packages">View All Packages</router-link>
            <router-link class="dropdown-item" to="/pages/add-applications-to-package">Add Application(s) to the Package</router-link>
            <router-link class="dropdown-item" to="/pages/add-applications-simple">Add Application(s) - Simple</router-link>
            <div class="dropdown-divider"></div>
            <router-link class="dropdown-item" to="/pages/fee-details">Fee Details</router-link>
            <router-link class="dropdown-item" to="/pages/searches-stc/purchase">Searches & STCs</router-link>
            <router-link class="dropdown-item" to="/pages/title-searches/purchase">Title Searches</router-link>
            <router-link class="dropdown-item" to="/pages/info-and-history">Info & History</router-link>
            <router-link class="dropdown-item" to="/pages/package-deferred">Package Deferred</router-link>
            <router-link class="dropdown-item" to="/pages/package-pending">Package Pending</router-link>
            <router-link class="dropdown-item" to="/pages/package-validation">Package Validation</router-link>
            <router-link class="dropdown-item" to="/pages/request-review">Request Review</router-link>
            <router-link class="dropdown-item" to="/pages/submit-package">Submit Package</router-link>
            <router-link class="dropdown-item" to="/pages/send-to-efs">Send to EFS</router-link>
            <router-link class="dropdown-item" to="/pages/submit-package-defected">Submit Package - Defected</router-link>
            <router-link class="dropdown-item" to="/pages/withdraw">Withdraw</router-link>
          </div>
        </li>
        <li class="nav-item dropdown position-static">
          <a class="nav-link dropdown-toggle" href="#" id="wfApplication" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> WF Application </a>
          <div class="dropdown-menu dropdown-full-width" aria-labelledby="wfApplicationDropdown">
            <div class="row">
              <div class="col-3">
                <h6 class="dropdown-header mt-2">Applications</h6>
                <router-link to="/pages/applications" class="dropdown-item">Applications</router-link> <router-link to="/pages/applications-defected" class="dropdown-item">Applications - defected</router-link>
                <h6 class="dropdown-header mt-2">Claims</h6>
                <router-link to="/pages/edit-application/claimant" class="dropdown-item">Claimant</router-link> <router-link to="/pages/edit-application/claim-details" class="dropdown-item">Claim Details</router-link>
              </div>
              <div class="col-3">
                <h6 class="dropdown-header mt-2">Edit Application</h6>
                <router-link to="/pages/edit-application/applicant" class="dropdown-item">Applicant</router-link>
                <router-link to="/pages/edit-application/charge-or-notation" class="dropdown-item">Charge or Notation</router-link>
                <router-link to="/pages/edit-application/charges-liens-interests" class="dropdown-item">Charges</router-link>
                <router-link to="/pages/edit-application/declaration" class="dropdown-item">Declaration</router-link>
                <router-link to="/pages/edit-application/pid-legal-description" class="dropdown-item">Description of Land</router-link>
                <router-link to="/pages/edit-application/electronic-instrument" class="dropdown-item">Electronic Instrument</router-link>
                <router-link to="/pages/edit-application/execution" class="dropdown-item">Execution</router-link>
                <router-link to="/pages/edit-application/fee-simple" class="dropdown-item">Fee Simple</router-link>
                <router-link to="/pages/edit-application/form-validation" class="dropdown-item">Form Validation</router-link>
                <router-link to="/pages/edit-application/local-government" class="dropdown-item">Local Government</router-link>
                <router-link to="/pages/edit-application/supporting-evidence" class="dropdown-item">Supporting Evidence</router-link>
                <router-link to="/pages/edit-application/transfer-details" class="dropdown-item">Transfer Details</router-link>
                <router-link to="/pages/edit-application/transferor" class="dropdown-item">Transferor</router-link>
              </div>
              <div class="col-3">
                <h6 class="dropdown-header mt-2">Mortgage</h6>
                <router-link to="/pages/mortgage-borrower" class="dropdown-item">Mortgage - Borrower</router-link>
                <router-link to="/pages/mortgage-terms" class="dropdown-item">Mortgage - Terms</router-link>
              </div>
              <div class="col-3">
                <h6 class="dropdown-header mt-2">PTT</h6>
                <router-link to="/pages/edit-application/questionnaire" class="dropdown-item">Questionnaire</router-link>
                <router-link to="/pages/edit-application/part-a-individual" class="dropdown-item">Part A - Individual</router-link>
                <router-link to="/pages/edit-application/part-a-corporation" class="dropdown-item">Part A - Corporation</router-link>
                <router-link to="/pages/edit-application/part-e-terms" class="dropdown-item">Part E - Terms</router-link>
                <router-link to="/pages/edit-application/part-h-calculation" class="dropdown-item">Part H - Calculation</router-link>
                <router-link to="/pages/edit-application/first-time-home-buyer" class="dropdown-item">First Time Home Buyer</router-link>
                <router-link to="/pages/edit-application/certification" class="dropdown-item">Certification</router-link>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/download">Download</router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  // watch: {
  //   $route() {
  //     $("#navbar").collapse("hide");
  //   }
  // }
};
</script>

<style lang="scss">
.dropdown-full-width {
  left: 1.5rem;
  right: 1.5rem;
}
</style>
